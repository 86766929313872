import React from "react"
import { Fade } from "react-awesome-reveal"

import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"

import logoBg from "../images/orange.svg"
import giftImg from "../images/giftCard.png"
import cardsImg from "../images/gift-cards.png"
import leftOver from "../images/gift-left-over.png"
import checkIco from "../images/circle-check-orange.svg"
import pdfFile from "../../static/VOP-Fresh_Market.pdf"

const GiftCardPage = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="concrete s hPadSec relative">
        <div className="absolute inset-0 bg-gradient-to-r from-white/75" />
        <div className="content text-left !text-black grid grid-cols-1 lg:grid-cols-2 gap-20">
          <div className="flex flex-col justify-center">
            <p className="text-lg">
              <span>Darujte vašim blízkym to, čo ich skutočne poteší.</span>
            </p>
            <h2 className="b text-left !font-bold">
              <span className="text-tert">Darčeková karta</span> <br />
              <span className="text-black">Fresh Market</span>
            </h2>

            <div className="font-semibold">
              Ak hľadáte darček k narodeninám alebo k sviatku, vami obdarovaný
              určite ocení darček, ktorý si vyskladá presne podľa toho, čo sa mu
              páči a čo mu chutí.
            </div>
            <Fade>
              <div className="buttonsCon sellers pt-6">
                <a
                  className="tag button"
                  href="https://freshmarket.giftify.net/index.php"
                  target="_blank"
                >
                  Kúpiť kartu
                </a>
                <AnchorLink
                  to="/darcekova-karta#ako-to-funguje"
                  className="tag unclicked button"
                >
                  Ako to funguje?
                </AnchorLink>
              </div>
            </Fade>
          </div>
          <div className="relative flex justify-center">
            <img
              src={logoBg}
              alt="logo"
              className="opacity-90 mix-blend-multiply"
            />
            <img
              src={giftImg}
              alt="logo"
              className="absolute object-contain h-full w-full inset-0"
            />
          </div>
        </div>
      </div>
      <div className="concrete hPadSec">
        <div className="content grid grid-cols-1 lg:grid-cols-2 gap-20">
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 p-4 bg-white shadow-lg">
              <img src={checkIco} />
              <p>
                Darčeková karta je platobná karta s predplateným kreditom.
                Môžete ju nabiť len raz a to v ľubovoľnej hodnote{" "}
                <span className="font-bold text-main">od 10 € do 250 €</span>.
              </p>
            </div>
            <div className="flex gap-4 p-4 bg-white shadow-lg">
              <img src={checkIco} />
              <p>
                Darčeková karta je platná po dobu{" "}
                <span className="font-bold text-main">12 mesiacov</span> od
                dátumu jej aktivácie.
              </p>
            </div>
            <div className="flex gap-4 p-4 bg-white shadow-lg">
              <img src={checkIco} />
              <p>
                Darčekovú kartu je možné použiť opakovane až do vyčerpania výšky
                kreditu.
              </p>
            </div>
            <div className="flex gap-4 p-4 bg-white shadow-lg">
              <img src={checkIco} />
              <p>
                Darčeková karta sa používa prostredníctvom načítania
                magnetického pásika.
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-center order-first lg:order-none"
            id="ako-to-funguje"
          >
            <p className="text-lg">Ako to funguje</p>
            <h2 className="text-left b">
              <span className="text-tert">Ako funguje</span>
              <span> darčeková</span>
              <br />
              <span className="text-black">karta Fresh Market?</span>
            </h2>
            <p>
              <span className="text-tert">
                Kartou je možné platiť vo všetkých prevádzkach vo Fresh Markete,
                ktoré ponúkajú možnosť platby kartou.
              </span>{" "}
              Darčekovú kartu nemožno uplatniť v obchodoch, kde sa dá platiť iba
              hotovosťou: Davídkovo koření, Samé dobroty a Boutique Balkan.
            </p>
          </div>
        </div>
        <div className="content">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 concrete s p-12 mt-20 relative shadow">
            <div className="absolute inset-0 bg-gradient-to-r from-white/75" />
            <div className="relative z-10">
              <h2 className="b text-left !mt-0">
                Ako získať Fresh Market darčekovú kartu?
              </h2>
              <p>
                <a
                  href="https://freshmarket.giftify.net/index.php"
                  target="_blank"
                  className="text-tert underline font-bold"
                >
                  Kartu si môžete zakúpiť online TU
                </a>
                , alebo priamo vo Fresh Markete počas otváracích hodín podľa
                dohody na emailovej adrese marketing@freshmarket.sk alebo na{" "}
                <a href="tel:+421 910 879 805" className="font-bold text-tert">
                  telefónnom čísle +421 910 879 805
                </a>
              </p>
              <p className="text-sm mt-4">
                Karta je platná jeden rok od vydania a kúpa darčekovej karty je
                možná len debetnou alebo kreditnou kartou, nie v hotovosti.
                Všeobecné obchodné podmienky používania darčekovej karty si
                prečítate TU.
              </p>
              <Fade>
                <div className="buttonsCon sellers pt-6">
                  <a
                    className="tag button"
                    href="https://freshmarket.giftify.net/index.php"
                    target="_blank"
                  >
                    Kúpiť kartu
                  </a>
                  <a href={pdfFile} className="tag unclicked button">
                    Obchodné podmienky
                  </a>
                </div>
              </Fade>
            </div>
            <div className="relative z-10 flex items-center justify-center">
              <img src={cardsImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="concrete g pt-24">
        <div className="content grid grid-cols-1 lg:grid-cols-2 lg:gap-20 text-white">
          <div className="flex items-end justify-center">
            <img src={leftOver} />
          </div>
          <div className="pb-12 lg:pb-24 order-first lg:order-last">
            <div className="smallCut o">
              <span>Skontrolovať zostatok</span>
            </div>
            <h2 className="b text-left !text-white">
              Už máte svoju darčekovú kartu?
            </h2>
            <p>
              Peňažný zostatok si ako držiteľ karty viete jednoducho
              skontrolovať cez QR kód, ktorý nájdete na zadnej strane karty
              alebo zadajte Token číslo zo zadnej strany vašej karty TU.
            </p>
            <Fade>
              <div className="buttonsCon sellers pt-6">
                <a
                  className="tag button"
                  href="https://freshmarket.giftify.me/sk_SK/"
                  target="_blank"
                >
                  Skontrolovať zostatok
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GiftCardPage
